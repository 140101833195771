import React from 'react';
import myMarker from '@img/my-marker.webp';
import placeholder from '@img/placeholder.webp';

export const MyPositionMarker = () => (
    <div style={{
        width: '22px',
        height: '22px',
        backgroundImage: `url(${myMarker})`,
        backgroundSize: 'cover',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-22px',
        marginLeft: '-11px'
    }}>
    </div>
);

export const PlaceholderCard = () => (
    <div className='Card' aria-hidden="true">
        <div className='image'>
            <img src={placeholder} alt="firullife-img"/>
        </div>
        <div className="detail">
            <div className = "detail-text">
                <p className="card-text placeholder-glow">
                    <span className="placeholder col-11"></span>
                </p>
                <p className="card-text placeholder-glow">
                    <span className="placeholder col-9"></span>
                    <span className="placeholder col-9"></span>
                    <span className="placeholder col-9"></span>
                </p>
            </div>
        </div>
    </div>
);

export const formatOptionLabel = ({ label, image }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        {image && <img src={image} alt="imagen" style={{ width: '20px', marginRight: '10px' }} />}
        <div>{label}</div>
    </div>
);

export const ContinueButton = ({ isActive, onClick, label = "Continuar" }) => (
    <button
        className={`btn ${isActive ? "btn-primary" : "btn-secondary"} w-100`}
        onClick={onClick}
        disabled={!isActive}
    >
        {label}
    </button>
);

export const ReturnButton = ({ onClick, label = "Volver", isRightAligned = false }) => (
    <p className={`return ${isRightAligned ? "right" : ""}`} onClick={onClick}>
        {isRightAligned ? (
            <>
                {label}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 5L19 12M19 12L12 19M19 12L5 12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </>
        ) : (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 19L5 12M5 12L12 5M5 12H19" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                {label}
            </>
        )}
    </p>
);

export const Disclaimer = ({ text }) => {
    return (
        <div className="disclaimer">
            <div style={{ float: "left", marginRight: "10px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#2DB1EF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 16V12M12 8H12.01" stroke="#2DB1EF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div style={{ marginLeft: "35px",marginTop:"3px" }}  dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    );
};